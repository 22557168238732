import { reducer as toastrReducer } from 'react-redux-toastr';
// import external modules
import { combineReducers } from 'redux';

import customizer from './customizer/';
import defaultReducer from './default';
import indicateurReducer from './indicateur';
import pageReducer from './page';
import serviceReducer from './service';
import moduleReducer from './module';
import menuReducer from './menu';
import utilisateurReducer from './utilisateur';
import userReducer from './user';
import campagneReducer from './campagne';
import documentationReducer from './documentation';
import mediaReducer from './media';
import contactReducer from './contact';
import messageReducer from './message';
import newsletterReducer from './newsletter';
import compteurReducer from './compteur';
import alertReducer from './alert';
import weblinkReducer from './weblink';
import localiteReducer from './localite';
import partenaireReducer from './partenaire';
import actualiteReducer from './actualite';
import applicationReducer from './application';
import echeanceFiscaleReducer from './echeanceFiscale';
import projectReducer from './project';
import tableReducer from './table';
import graphReducer from './graph';
import templateReducer from './template';
import categorieDocumentReducer from './categorieDocument';
import categorieLienReducer from './categorieLien';
import categoriePublicationReducer from './categoriePublication';
import categorieFaqReducer from './categorieFaq';
import FaqReducer from './Faq';
import centreReducer from './centre';

const rootReducer = combineReducers({
    toastr: toastrReducer, // <- Mounted at toastr.
    customizer: customizer,
    menu: menuReducer,
    indicateur: indicateurReducer,
    project: projectReducer,
    echeanceFiscale: echeanceFiscaleReducer,
    application: applicationReducer,
    actualite: actualiteReducer,
    partenaire: partenaireReducer,
    localite: localiteReducer,
    weblink: weblinkReducer,
    alert: alertReducer,
    newsletter: newsletterReducer,
    compteur: compteurReducer,
    message: messageReducer,
    contact: contactReducer,
    media: mediaReducer,
    documentation: documentationReducer,
    campagne: campagneReducer,
    service: serviceReducer,
    page: pageReducer,
    template: templateReducer,
    centre: centreReducer,
    Faq: FaqReducer,
    categorieFaq: categorieFaqReducer,
    categoriePublication: categoriePublicationReducer,
    categorieLien: categorieLienReducer,
    categorieDocument: categorieDocumentReducer,
    graph: graphReducer,
    table: tableReducer,
    module: moduleReducer,
    default: defaultReducer,
    user: userReducer,
    utilisateur: utilisateurReducer,
});

export default rootReducer;